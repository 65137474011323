import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import AnimatedComponent from '../AnimatedComponent';

import Dots from "../../images/footer-dots.webp";
import Phone from "../../images/phone.webp";
import Email from "../../images/email.webp";

import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

import './footer.css';

import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Footer = ({ initiallyHidden }) => {
    const currentYear = new Date().getFullYear();
    const baseUrl = "https://backend.abrecogroup.com/storage/";
    const [siteFooter, setFooter] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [contactUs, setContactUs] = useState([]);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    const [subject, setubject] = useState("");
    const [subjectError, setSubjectError] = useState("");
    const [pageUrl, setPageUrl] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    const [verified, setVerified] = useState(false);
    const [seo, setSeo] = useState("");
    const [contactMain, setContactMain] = useState("");
    const [animationComplete, setAnimationComplete] = useState(false);
    const [contentLoaded, setContentLoaded] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setAnimationComplete(true);
        }, 10);
        return () => clearTimeout(timeout);
    }, []);
    
    
    useEffect(() => {
        // Set the page URL dynamically when the component mounts or URL changes
        setPageUrl(window.location.href);

        // Set the page title dynamically based on the URL path
        const currentPath = window.location.pathname;
        setPageTitle(formatTitle(currentPath));
    }, []);

    // Function to format the URL path into a human-readable title
    const formatTitle = (path) => {
        const pathWithoutSlash = path.replace(/^\/+/, '');
        const words = pathWithoutSlash.split('-');
        return words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ') || 'Home';
    };


    const contactSeo = async () => {
        const { data } = await axios.get(
            "https://backend.abrecogroup.com/api/contact"
        );
        const seo = data.data.contact;
        setSeo(seo)
        const contactMain = data.data.contact[0]
        setContactMain(contactMain)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // if (!url || !page) {
        //     console.log("URL or Page is missing.");
        //     return;
        // }

        if (validateForm() && verified) {
            setIsLoading(true);

            const recaptchaToken = await window.grecaptcha.execute('6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV', { action: 'submit' });

            const data = {
                name,
                email,
                phone,
                subject,
                message,
                url: pageUrl,
                page: pageTitle,
                recaptchaToken,
            };

            axios
                .post("https://backend.abrecogroup.com/api/contact-form", data)
                .then((response) => {
                    event.target.reset();
                    const responseMsg = response.data.message;

                    if (responseMsg === "Successful") {
                        console.log(responseMsg);
                        window.location.href = "/thank-you";
                    } else {
                        console.log(responseMsg);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }

    const validateForm = () => {
        let isValid = true;

        if (!verified) {
            isValid = false;
        }

        if (!name.trim()) {
            setNameError("Please enter your name.");
            isValid = false;
        } else {
            setNameError("");
        }

        if (!email.trim()) {
            setEmailError("Please enter your email address.");
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError("Email is invalid");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!phone.trim()) {
            setPhoneError("Please enter your phone number.");
            isValid = false;
        } else if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(phone)) {
            setPhoneError("Phone is invalid");
            isValid = false;
        } else {
            setPhoneError("");
        }

        if (!subject.trim()) {
            setSubjectError("Please enter the subject.");
            isValid = false;
        } else {
            setSubjectError("");
        }

        return isValid;
    };

    const contactDetails = async () => {
        try {
            const { data } = await axios.get(
                "https://backend.abrecogroup.com/api/general"
            );
            const contactUs = data.data.general;
            setContactUs(contactUs);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    useEffect(() => {
        const siteFooterApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.abrecogroup.com/api/general"
                );

                const siteFooter = data.data.general || {};
                setFooter(siteFooter);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        siteFooterApi();
    }, []);

    return (
        <GoogleReCaptchaProvider reCaptchaKey="6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV">
            <>
                {!loading && siteFooter && Object.keys(siteFooter).length > 0 && (
                    <footer style={{ display: initiallyHidden ? 'none' : 'block' }}>
                        <div className='container'>
                            <div className='rowData'>
                                <div className='contact' >

                                    {/* <AnimatedComponent animation="animate__fadeInUp" delay=".1s" duration="1s"> */}
                                    <div className="dots dot-pattern-ten-six xl">
                                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                        <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                                    </div>
                                    <span>Contact Us</span>
                                    {siteFooter?.site_title ? (
                                        <>
                                            <h2 dangerouslySetInnerHTML={{ __html: siteFooter.site_title }}></h2>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                    {siteFooter?.address ? (
                                        <>
                                            <p dangerouslySetInnerHTML={{ __html: siteFooter.address }}></p>
                                        </>
                                    ) : (
                                        <p></p>
                                    )}
                                    <ul className='m-0'>
                                        <li>
                                            {siteFooter?.mobile ? (
                                                <>
                                                    <img src={Phone} alt="Phone Number" />
                                                    <a href={`tel:${siteFooter.mobile.replace(/\s/g, '')}`}>{siteFooter.mobile}</a>
                                                </>
                                            ) : (
                                                <p></p>
                                            )}
                                        </li>
                                        <li>
                                            {siteFooter?.email ? (
                                                <>
                                                    <img src={Email} alt="Email Address" />
                                                    <a href={`mailto:${siteFooter.email.replace(/\s/g, '')}`}>{siteFooter.email}</a>
                                                </>
                                            ) : (
                                                <p></p>
                                            )}
                                        </li>
                                    </ul>
                                    {/* </AnimatedComponent> */}

                                </div>
                                <div className='form'>
                                    <div className="form-container">
                                        <form onSubmit={handleSubmit}>                                             
                                            <div className="form-group">
                                                <input id="name" type="text" className="form-control" value={name} onChange={(event) => setName(event.target.value)} name="name" placeholder=" " autoComplete="off" />
                                                <label htmlFor="name">Your Name *</label>
                                                {nameError && <p className="error">{nameError}</p>}
                                            </div>
                                            <div className="form-group">
                                                <input id="email" type="text" className="form-control" value={email} onChange={(event) => setEmail(event.target.value)} name="email" placeholder=" " autoComplete="off" />
                                                <label htmlFor="email">Email Address *</label>
                                                {emailError && <p className="error">{emailError}</p>}
                                            </div>
                                            <div className="form-group">
                                                <input id="phone" type="text" className="form-control" value={phone} onChange={(event) => setPhone(event.target.value)} name="phone" placeholder=" " autoComplete="off" />
                                                <label htmlFor="phone">Phone Number *</label>
                                                {phoneError && <p className="error">{phoneError}</p>}
                                            </div>
                                            <div className="form-group">
                                                <input id="subject" type="text" className="form-control" name="subject" value={subject} onChange={(event) => setubject(event.target.value)} placeholder=" " autoComplete="off" />
                                                <label htmlFor="subject">Subject</label>
                                                {subjectError && <p className="error">{subjectError}</p>}                                            
                                            </div>
                                            <div className="form-group">
                                                <textarea id='message' className="form-control" value={message} onChange={(event) => setMessage(event.target.value)} name="message" placeholder=" " autoComplete="off" />
                                                <label htmlFor="message">Your Message</label>
                                            </div>
                                            <div>
                                                <input id="url" type="hidden" name="url" value={pageUrl} />
                                                <input id="page" type="hidden" name="page" value={pageTitle} />
                                            </div>
                                            <div className="form-group">
                                                <GoogleReCaptcha sitekey="6Lc8pmkqAAAAAL3IMueW2Im0QN0knNemk7ihKabV"
                                                    onVerify={() => {
                                                        console.log("reCAPTCHA verified!");
                                                        setVerified(true);
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group text-left">
                                                <button type="submit" className="submit-button">
                                                    {isLoading ? (
                                                        <>
                                                            <div className="btn-loader"></div> Sending ...
                                                        </>
                                                    ) : (
                                                        "JOIN THE ABRECO FAMILY TODAY!"
                                                    )}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container-fluid'>
                            <div className='rowData'>
                                <ul className='extraLinks'>
                                    <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                </ul>
                                <ul className='socialLinks'>
                                    {siteFooter && siteFooter.facebook && (
                                        <li><a href={siteFooter.facebook} target="_blank"><FaFacebookF /></a></li>
                                    )}
                                    {siteFooter && siteFooter.twitter && (
                                        <li><a href={siteFooter.twitter} target="_blank"><FaXTwitter /></a></li>
                                    )}
                                    {siteFooter && siteFooter.instagram && (
                                        <li><a href={siteFooter.instagram} target="_blank"><FaInstagram /></a></li>
                                    )}
                                    {siteFooter && siteFooter.youtube && (
                                        <li><a href={siteFooter.youtube} target="_blank"><FaYoutube /></a></li>
                                    )}
                                    {siteFooter && siteFooter.linkdln && (
                                        <li><a href={siteFooter.linkdln} target="_blank"><FaLinkedinIn /></a></li>
                                    )}
                                </ul>
                                <p>&copy; Abreco Group {currentYear} | All Rights Reserved | <span>Digitally Empowered By <Link to="https://www.netventure.in/" target="_blank">NetVenture</Link></span></p>
                            </div>
                        </div>
                    </footer>
                )}
            </>
        </GoogleReCaptchaProvider>
    )
}

export default Footer